/* Force Override Swiper Navigation Buttons */
.swiper-button-prev,
.swiper-button-next {
  color: white !important; /* Change button color */
  background: rgba(0, 0, 0, 0.5) !important; /* Add background color */
  width: 40px !important; /* Adjust button size */
  height: 40px !important;
  border-radius: 50% !important; /* Make it circular */
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: rgba(0, 0, 0, 0.8) !important; /* Darken on hover */
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px !important; /* Change arrow size */
  font-weight: bold !important;
}
